export default function VisaInfiniteCa() {
	return (
		<div class="flex flex-col gap-8 md:mt-24">
			<img
				src="https://images.ctfassets.net/rdsy7xf5c8dt/oq9u8ytZj41pYIiXV4tK7/d05c246487c999fb15a8bcdf83e11821/Visa_Infinite_Logo.svg"
				alt="Visa Infinite®"
				width={96}
				height={42}
				class="mx-auto w-full max-w-64"
			/>

			<p>
				As a Visa Infinite® cardholder, you have access to two great benefits with Troon Golf, so you can experience
				new courses on your next getaway.
			</p>
		</div>
	);
}
